import { App, getInitialProps } from '@synqly/ui/pages/_app'

import '@synqly/ui/styles/theme-management.css'

export { BackofficeApp as default }

BackofficeApp.getInitialProps = getInitialProps

function BackofficeApp(props) {
  return <App defaultMetadata={metadata} {...props} />
}

const metadata = {
  other: {
    links: [
      {
        href: '/',
        rel: 'synqly:nav:primary:/overview',
        title: 'Organizations',
      },
      {
        href: '/settings',
        rel: 'synqly:nav:primary:/settings',
        title: 'Settings',
      },
    ],
  },
}
